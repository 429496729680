import classes from "./index.module.scss";

import LogoIcon from "../Icons/Logo";

const {
    header,
    header__logo,
    header__logoText,
    header__contentWrapper,

    headerMargin
} = classes;

const Header = ({children = ""}) => {


    return (
        <>
            <header className={header}>
                <LogoIcon className={header__logo} />
                <h1 className={header__logoText}>
                    MUP//Me
                </h1>
                <div className={header__contentWrapper}>
                    {children}
                </div>
            </header>
            <div className={headerMargin}>

            </div>
        </>
    );
}

export default Header;