import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import Splashscreen from './components/Splashscreen';
import { Provider } from 'react-redux';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

let readyState = false;

document.addEventListener("DOMContentLoaded", () => {
    readyState = true;
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    global.api = 'http://mup-me-app';
} else {
    global.api = '';
}

const App = () => {

    const [isReady, setReady] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            if (readyState) {
                setReady(true);
                clearInterval(timer);
            }
        }, 3000);
    }, []);

    return (
        <React.StrictMode>
            <Provider store={store}>
                <div className="app">
                    <Splashscreen isReady={isReady}>
                        <RouterProvider router={router} />
                    </Splashscreen>
                </div>
            </Provider>
        </React.StrictMode>
    );
}

root.render(<App />);