import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Buttons";
import BackIcon from "../Icons/Back";
import UnderHeaderBackground from "../UnderHeaderBackground";

import classes from "./styles.module.scss";

const {
    stories,
    stories__background,
    stories__track,

    stories__card,
    stories__cardImg,
    stories__cardText,

    storiesViewer,
    storiesViewer_shown,
    storiesViewer__img,
    storiesViewer__imgBlocker,
    storiesViewer__header,
    storiesViewer__backButton,
    storiesViewer__storyMap,
    storiesViewer__storyMapItem,
    storiesViewer__storyMapItemProgress
} = classes;

let timer = 0;
let pause = false;
const isTouchCapable = 'ontouchstart' in window ||
window.DocumentTouch && document instanceof window.DocumentTouch ||
navigator.maxTouchPoints > 0 ||
window.navigator.msMaxTouchPoints > 0;

const Stories = () => {

    const [storiesData, setStoriesData] = useState([]);

    const [storieShow, setStorieShow] = useState(-1);

    const [currentSlide, setCurrentSlide] = useState(0);

    const [slideProgress, setSlideProgress] = useState(0);

    const [touchDelay, setTouchDelay] = useState(0);
    const [touchTimer, setTouchTimer] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getStories();
    }, []);

    const getStories = async () => {
        const response = await fetch(global.api + '/wp-json/api/stories/');
        const json =  await response.json();

        if (json.status === 'success') {
            setStoriesData(json.data);
        }
    }

    const keyboard = (e) => {
        if (e.keyCode === 27) {
            stopShowing();
        }
        if (e.keyCode === 37) {
            previousSlide();
        }
        if (e.keyCode === 39) {
            nextSlide();
        }
    }
    
    const showStories = (i) => {
        setStorieShow(i);
        setCurrentSlide(0);
        navigate("#stories")
        timer = setInterval(() => {
            setSlideProgress(slideProgress => !pause ? slideProgress + 1 : slideProgress);
        }, 1000);

        document.addEventListener("keydown", keyboard);
    }

    useEffect(() => {
        if (location.hash !== "#stories" && storieShow >= 0) {
            stopShowing();
        }
    }, [location.hash]);

    useEffect(() => {
        if (slideProgress >= 11) {
            setSlideProgress(0);
            nextSlide();
        }
    }, [slideProgress]);

    const nextSlide = () => {
        setSlideProgress(0);

        if (currentSlide + 1 < storiesData[storieShow]?.stories.length) {
            setCurrentSlide(currentSlide + 1);
        }
        else {
            if (storieShow < storiesData.length - 1) {
                setStorieShow(storieShow => storieShow + 1);
                setCurrentSlide(0);
            }
            else {
                stopShowing();
            }
        }
    }
    
    const previousSlide = () => {
        setSlideProgress(0);
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
        else {
            if (storieShow > 0) {
                setStorieShow(storieShow => storieShow - 1);
                setCurrentSlide(storiesData[storieShow - 1].stories.length - 1);
            }
        }
    }

    const stopShowing = () => {
        clearInterval(timer);
        setSlideProgress(0);
        setStorieShow(-1);
        setCurrentSlide(0);
        clearInterval(touchTimer);
        navigate(-1);
        document.removeEventListener("keydown", keyboard);
    }

    const startTouch = () => {
        clearInterval(touchTimer);
        pause = false;
        setTouchDelay(0);

        setTouchTimer(setInterval(() => {
            setTouchDelay(touchDelay => touchDelay + 100);
        }, 100));
    }

    useEffect(() => {
        if (touchDelay > 200 && !pause) {
            clearInterval(touchTimer);
            pause = true;
        }
    }, [touchDelay]);

    const endTouch = (e) => {
        clearInterval(touchTimer);
        pause = false;
        
        if (touchDelay < 300) {
            let touchX = isTouchCapable ? e.changedTouches[0].pageX : e.pageX;

            if (touchX > window.innerWidth / 2) {
                nextSlide();
            }
            else {
                previousSlide();
            }
        }
        setTouchDelay(0);
    }

    return (
        <>
            <div className={stories}>
                <UnderHeaderBackground className={stories__background} />
                <div className={stories__track}>
                    {
                        storiesData.map(({title, preview}, key) => (
                            <div className={stories__card} key={key} onClick={() => showStories(key)}>
                                <img src={preview} alt={title} className={stories__cardImg} />
                                <p className={stories__cardText}>
                                    {title}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div
                className={storiesViewer + (storieShow !== -1 ? " " + storiesViewer_shown : "")}

                onMouseDown={isTouchCapable ? null : startTouch}
                onMouseUp={isTouchCapable ? null : endTouch}
                
                onTouchStart={isTouchCapable ? startTouch : null}
                onTouchEnd={isTouchCapable ? endTouch : null}
            >
                {
                    storieShow !== -1 ? (
                        <>
                            <img
                                src={storiesData[storieShow].stories[currentSlide]}
                                alt=""
                                className={storiesViewer__img}
                                style={{
                                    opacity: slideProgress > 9 ? 0 : 1
                                }}
                            />
                            <div className={storiesViewer__imgBlocker} />
                            <div className={storiesViewer__header}>
                                <Button
                                    variant="icon"
                                    background="black"
                                    className={storiesViewer__backButton}
                                    onClick={stopShowing}
                                >
                                    <BackIcon />
                                </Button>
            
                                <div className={storiesViewer__storyMap}>
                                    {
                                        storiesData[storieShow].stories.map((item, i) => (
                                            <div className={storiesViewer__storyMapItem} key={i}>
                                                <div
                                                    className={storiesViewer__storyMapItemProgress}
                                                    style={{
                                                        minWidth: (currentSlide === i ? slideProgress * 10 + "%" : (
                                                            currentSlide > i ? "100%" : "0%"
                                                        )),
                                                        transition: (currentSlide !== i || pause || slideProgress < 1 ? "none" : null)
                                                    }}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    ) : ""
                }
            </div>
        </>
    );
}

export default Stories;