import { createSlice } from '@reduxjs/toolkit'

export const localeReducer = createSlice({
  name: 'locale',
  initialState: {
    value: localStorage.getItem("locale") ? localStorage.getItem("locale") : "en-GB",
  },
  reducers: {
    setLocale: (state, action) => {
      state.value = action.payload;
      localStorage.setItem("locale", action.payload);
    },
  },
});

export const { setLocale } = localeReducer.actions;

export default localeReducer.reducer;