const LogoIcon = (props = {className: ""}) => (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M21.0142 10.5212C22.248 5.59865 18.2486 1.24393 18.2486 1.24393L17.2008 6.35235L15.0459 9.1915L15.9405 10.7826L15.048 11.2769L12.9298 8.3418L13.7142 6.56682L16.443 5.1595L13.4221 4.16069L11.592 6.29107L11.0364 8.64614L10.3378 6.29107L8.36063 4.16069L5.52557 4.80614L7.90106 6.39116L8.85085 8.3418L6.90429 11.3709L5.84012 10.7806L6.73476 9.18946L4.58191 6.35031L3.53408 1.24188C3.53408 1.24188 -0.463196 5.5966 0.768463 10.5192C2.00217 15.4417 7.30668 16.13 7.30668 16.13L3.93238 18.4116L1.95723 18.5709L1.62021 20.7401L3.40948 21.5714L4.98225 19.8475L8.89987 18.4116L6.66531 21.6674L11.0364 24L15.2624 21.6695L13.0789 18.5893V18.4626L16.8372 19.8536L18.3916 21.5775L20.1727 20.7462L19.8296 18.577L17.8503 18.4177L14.476 16.1362C14.476 16.1321 19.7805 15.4417 21.0142 10.5212Z" fill="#FFCC4D"/>
        <path d="M11.0364 14.8085V17.4577C11.0364 17.4577 10.6667 16.9042 9.5045 16.5631C7.97259 16.1158 7.71727 14.8085 7.71727 14.8085H11.0364ZM11.0405 14.8085V17.4577C11.0405 17.4577 11.4102 16.9042 12.5724 16.5631C14.1043 16.1158 14.3597 14.8085 14.3597 14.8085H11.0405Z" fill="#5C913B"/>
        <path d="M8.25 9.28751C8.25 9.28751 9.94978 9.89414 11.0364 9.35082V14.5532H7.71727C7.71727 14.5532 7.59063 13.4359 8.03591 11.9673C8.31574 11.0502 7.58 10.3088 7.58 10.3088L8.25 9.28751ZM13.6264 9.28751C13.6264 9.28751 12.1271 9.89414 11.0405 9.35082V14.5532H14.3597C14.3597 14.5532 14.4863 13.4359 14.041 11.9673C13.7612 11.0502 14.35 10.3088 14.35 10.3088L13.6264 9.28751ZM21.4085 18.2237C21.4162 18.4422 21.3799 18.66 21.3016 18.8641C21.2234 19.0683 21.1048 19.2546 20.9529 19.4119C20.8011 19.5692 20.6191 19.6943 20.4179 19.7797C20.2166 19.8652 20.0002 19.9092 19.7816 19.9092C19.563 19.9092 19.3466 19.8652 19.1453 19.7797C18.9441 19.6943 18.7621 19.5692 18.6103 19.4119C18.4585 19.2546 18.3399 19.0683 18.2616 18.8641C18.1833 18.66 18.147 18.4422 18.1547 18.2237C18.1697 17.8022 18.3476 17.4029 18.6511 17.11C18.9545 16.8171 19.3598 16.6534 19.7816 16.6534C20.2034 16.6534 20.6087 16.8171 20.9122 17.11C21.2156 17.4029 21.3936 17.8022 21.4085 18.2237Z" fill="#226699"/>
        <path d="M13.1423 3.6215C13.1423 4.07086 12.2497 4.43443 11.1467 4.43443C10.0437 4.43443 9.15114 4.07086 9.15114 3.6215C9.15114 3.17214 10.0437 2.80856 11.1467 2.80856C12.2497 2.80856 13.1423 3.17214 13.1423 3.6215Z" fill="#FFCC4D"/>
        <path d="M11.0997 0C9.37171 0 7.97256 0.806809 7.97256 1.80357C7.97256 2.80034 9.37171 3.60715 11.0997 3.60715C12.8277 3.60715 14.2269 2.80034 14.2269 1.80357C14.2269 0.806809 12.8277 0 11.0997 0ZM8.89784 2.55932C8.51384 2.39183 8.51384 1.94655 8.5792 1.62587C8.64252 1.30723 9.18584 0.988596 9.18584 0.988596C9.18584 0.988596 8.81614 1.76272 9.12865 2.09362C9.44116 2.42655 9.28184 2.72681 8.89784 2.55932ZM10.2173 2.53276C10.0784 2.48987 10.0621 2.33668 9.97427 2.07728C9.88644 1.81787 9.79044 1.62792 9.85375 1.30723C9.91707 0.988596 10.7137 0.72102 10.7137 0.72102C10.7137 0.72102 10.491 1.37055 10.5544 1.68919C10.6177 2.00783 10.873 2.34689 10.873 2.34689C10.873 2.34689 10.3562 2.57566 10.2173 2.53276ZM12.1925 2.07728C12.1046 2.33668 12.0883 2.48987 11.9494 2.53276C11.8105 2.57566 11.2917 2.35098 11.2917 2.35098C11.2917 2.35098 11.547 2.01192 11.6104 1.69328C11.6737 1.37464 11.451 0.725105 11.451 0.725105C11.451 0.725105 12.2476 0.990638 12.3109 1.31132C12.3763 1.62791 12.2803 1.81991 12.1925 2.07728ZM13.2709 2.55932C12.8869 2.72681 12.7276 2.42451 13.0401 2.09362C13.3527 1.76272 12.9829 0.988596 12.9829 0.988596C12.9829 0.988596 13.5263 1.30723 13.5896 1.62587C13.6529 1.94655 13.6529 2.39387 13.2709 2.55932ZM21.4024 18.3993C21.3749 18.5058 21.3065 18.5971 21.212 18.6534C21.1174 18.7096 21.0045 18.7262 20.8978 18.6996L18.6429 18.1134C18.5369 18.0845 18.4464 18.0154 18.3906 17.9208C18.3349 17.8261 18.3184 17.7135 18.3446 17.6068C18.3721 17.5004 18.4406 17.4092 18.5352 17.3533C18.6298 17.2974 18.7427 17.2813 18.8492 17.3086L21.1041 17.8948C21.1573 17.9076 21.2073 17.931 21.2512 17.9635C21.2951 17.996 21.3321 18.037 21.3599 18.084C21.3877 18.1311 21.4058 18.1832 21.4131 18.2374C21.4204 18.2915 21.4167 18.3466 21.4024 18.3993Z" fill="#FFCC4D"/>
        <path d="M20.2177 18.14C20.206 18.1869 20.1851 18.231 20.1562 18.2698C20.1273 18.3085 20.091 18.3411 20.0493 18.3656C20.0077 18.3902 19.9616 18.4061 19.9137 18.4126C19.8657 18.4191 19.817 18.416 19.7703 18.4035C19.7235 18.3914 19.6796 18.3701 19.6411 18.3409C19.6025 18.3117 19.5702 18.2751 19.5458 18.2333C19.5215 18.1915 19.5057 18.1453 19.4994 18.0974C19.4931 18.0495 19.4963 18.0008 19.5089 17.9541L20.0134 15.644C20.0255 15.5974 20.0467 15.5536 20.0758 15.5153C20.1049 15.4769 20.1412 15.4446 20.1828 15.4203C20.2243 15.396 20.2703 15.3802 20.318 15.3737C20.3657 15.3672 20.4142 15.3702 20.4607 15.3825C20.5075 15.3944 20.5514 15.4154 20.5899 15.4444C20.6284 15.4734 20.6608 15.5098 20.6851 15.5514C20.7094 15.5931 20.7252 15.6391 20.7316 15.6869C20.738 15.7347 20.7348 15.7833 20.7222 15.8298L20.2177 18.14ZM4.57172 23.7447C4.44916 23.7917 4.23265 23.5364 4.09172 23.1769L0.118951 13.0846C-0.0240275 12.7231 -0.0403672 12.3902 0.082186 12.3412L0.284399 12.2615C0.408994 12.2145 0.92576 12.3493 1.06874 12.7088L5.0415 22.8011C5.18448 23.1626 4.89648 23.614 4.77393 23.6651L4.57172 23.7447ZM9.36967 12.2513H12.7011V14.5532H9.36967V12.2513Z" fill="#FFCC4D"/>
    </svg>
);

export default LogoIcon;
