import { useSelector } from "react-redux";

import Button from "../../components/Buttons";
import Header from "../../components/Header";
import BellIcon from "../../components/Icons/Bell";
import Stories from "../../components/Stories";

import lang from "./locale.json";
import classes from "./styles.module.scss";

const {
    mainPage
} = classes;

const MainPage = ({}) => {

    const locale = useSelector((state) => state.locale.value);

    return (
        <div className={mainPage}>
            <Header>
                <Button
                    variant="icon"
                    background="black"
                    title={lang.notification[locale]}
                >
                    <BellIcon />
                </Button>
            </Header>
            <Stories />
            {lang.currentClient[locale]}
        </div>
    );
}

export default MainPage;