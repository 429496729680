import classes from "./index.module.scss";

const {
    button,
    button_icon,
    button_backgroundBlack
} = classes;

const backgrounds = ["white", "black"]

const Button = ({
    children = "Button",
    className = "",
    variant = "",
    background = backgrounds[0],
    title = "",
    onClick = () => {}
}) => {
    
    let tmpClassName = button;
    tmpClassName += variant === "icon" ? " " + button_icon : "";
    tmpClassName += background === backgrounds[1] ? " " + button_backgroundBlack : "";

    return (
        <button
            type="button"
            className={tmpClassName}
            title={title}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default Button;