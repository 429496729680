import { useSelector } from "react-redux";
import LogoIcon from "../Icons/Logo";

import lang from "./locale.json";
import classes from "./index.module.scss";

const {
    splashscreen,
    splashscreen__logo,
    splashscreen__name,
    splashscreen__title,
    splashscreen__description,
    splashscreen__info,
    splashscreen__copyright,
    splashscreen__location,
} = classes;

const Splashscreen = ({isReady = false, children = ""}) => {

    const locale = useSelector(state => state.locale.value);

    return (
        !isReady ? (
            <div className={splashscreen}>
                <LogoIcon className={splashscreen__logo} />
                <h1 className={splashscreen__name}>MUP//Me</h1>
                <p className={splashscreen__title}>
                    {lang.title[locale]}
                </p>
                
                <p className={splashscreen__description}>
                    {lang.description[locale]}
                </p>

                <div className={splashscreen__info}>
                    <p className={splashscreen__copyright}>
                        2023{(new Date()).getFullYear() > 2023 ? " - " + (new Date()).getFullYear() : ""} {lang.copyright[locale]}
                    </p>
                    <p className={splashscreen__location}>
                        {lang.budva[locale]}
                    </p>
                </div>
            </div>
        ) : children
    );
}

export default Splashscreen;