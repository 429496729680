import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import MainPage from "../../pages/MainPage";
import { setLocale } from "../../store/reducers/locale";



const MainWrapper = () => {

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const locales = {
        "ru": "ru-RU",
        "en": "en-GB",
        "me": "me-ME"
    };

    const location = useLocation();

    useEffect(() => {
        if (!locales[params.locale]) {
            if (navigator?.language) {
                if (
                    navigator.language === "sr-SR" ||
                    navigator.language === "sr-ME" ||
                    navigator.language === "me-ME"
                ) {
                    navigate("/me/");
                    dispatch(setLocale("me-ME"));
                }
                else if (
                    navigator.language === "ru-RU" ||
                    navigator.language === "be-BE" ||
                    navigator.language === "uk-UK"
                ) {
                    navigate("/ru/");
                    dispatch(setLocale("ru-RU"));
                }
                else {
                    navigate("/en/");
                    dispatch(setLocale("en-GB"));
                }
            }
            else {
                navigate("/en/");
                dispatch(setLocale("en-GB"));
            }
        }
        else {
            dispatch(setLocale(locales[params.locale]));
        }

    }, []);

    return location.pathname === "/" ? <MainPage /> : <Outlet />;
}

export default MainWrapper;